@charset "UTF-8";

/* ---------------------------
	body
--------------------------- */
html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}
body {
  width: 100%;
  height: 100%;
  font-family: $dflt-font-family;
  font-size: 1.4rem;
  line-height: $dflt-line-height;
  color: $dflt-font-color;
  letter-spacing: $dflt-letter-spacing;
}
.l-wrap {
  width: 100%;
  height: auto !important;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}
@include screen-mq(sm) {
  body {
    font-size: responsive-fs(16,$screen-sm);
    font-size: 1.3rem;
  }
}
@include screen-mq(xs) {
  body {
    font-size: 1.3rem;
    line-height: 1.75;
  }
}

/* ---------------------------
  header
--------------------------- */
header {
	width: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 9999;
	background: #fff;
	transition: 1.0s;
	text-decoration: none;
  padding: 16px 30px;
  box-shadow:0px 0px 10px -9px #000000;
  @include screen-mq(sm) {
    width: 100%;
    padding: 0;
    top: 0;
    position: fixed;
    margin-top: 0;
  }
  #nav_toggle {
    display: none;
    position: absolute;
    right: 12px;
    top: 40px;
    width: 34px;
    height: 36px;
    cursor: pointer;
    z-index: 101;
    @include screen-mq(sm) {
      display: block;
      top: 15px;
    }
    .nav_toggle_wrap {
      position: relative;
      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: $color-base-4;
        left: 0;
        transition: .35s ease-in-out;
          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            top: 11px;
          }
          &:nth-child(3) {
            top: 22px;
          }
      }
    }

  }
    /* #nav_toggle 切り替えアニメーション */
    @include screen-mq(sm) {
      &.open{
        #nav_toggle{
          span{
            &:nth-child(1) {
              top: 11px;
              transform: rotate(315deg);
            }
            &:nth-child(2) {
              width: 0;
              left: 50%;
            }
            &:nth-child(3) {
              top: 11px;
              transform: rotate(-315deg);
            }
          }
        }
        #global_nav {
          transform: translateY(756px);
        }
      }
    }
    .o-sect{
      .__inner{
          position: relative;
          display: flex;
          justify-content: space-between;
        @include screen-mq(sm) {
          padding: 14px;
        }
        .__logo{
          @include screen-mq(sm) {
            width: 100px;
          }
        }
        #global_nav{
          @include screen-mq(sm){
            position: absolute;
            top: -700px;
            left: 0;
            background:#f6f6f6;
            width: 100%;
            text-align: center;
            padding: 10px 0;
            transition: .5s ease-in-out;
          }
          ul{
            list-style: none;
            font-size: 16px;
            li {
              display: inline-block;
              margin-right: 32px;
              margin-top: 10px;
              
              @include screen-mq(sm) {
                display: block;
                margin: 16px 0;
              }
              a {
                font-size: 14px;
                position: relative;
                z-index: 0;
                cursor: pointer;
                &:before,&:after{
                  position: absolute;
                  top: 6px;
                  left: 50%;
                  width: 50px;
                  height: 50px;
                  border: 4px solid $color-base-4;
                  transform: translateX(-50%) translateY(-50%) scale(0.8);
                  border-radius: 50%;
                  background: transparent;
                  content: "";
                  opacity: 0;
                  transition: all 0.3s;
                  z-index: -1;
                }
                &:after{
                  border-width: 2px;
                  transition: all 0.4s;
                }
                &:hover:before{
                  opacity: 1;
                  transform: translateX(-50%) translateY(-50%) scale(1);
                }
                &:hover:after
                {
                  opacity: 1;
                  transform: translateX(-50%) translateY(-50%) scale(1.3);
                }
                @include screen-mq(sm){
                  width: 100%;
                  display: block;
                  padding: 8px 20px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
    a{
      transition: 1.0s;
      text-decoration: none;
      color: $dflt-font-color;
    }
	}
/* Toggle Button */


/* ---------------------------
  footer
--------------------------- */
.l-footer {
    text-align: center;
  .footer_content{
    background: $color-base-4;
    padding: 80px 0;
    color: $color-white;
    .l-container{
      .__wrap{
        .__inner{
          .__box{
            .footer_logo{
              margin-bottom: $spc-S;
              img{
                margin: 0 auto;
              }
            }
            .btn02{
              margin: $spc-R auto $spc-S auto;
            }
            .footer_sns{
              li{
                img{
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }
  }
  .copyright{
    @include screen-mq(sm) {
      padding-bottom: 50px;
    }
    .l-container{
      .__wrap{
        .__inner{
          padding: $spc-S;
        }
      }
    }
  }
}
	
/* ---------------------------
  main
--------------------------- */
.l-main {
  .__wrap{
    .__inner{
      .__box{
        .__block{

        }
      }
    }
  }
}

/* ---------------------------
  aside
--------------------------- */
.l-aside {
}

