@charset "UTF-8";

/* ---------------------------
  html elements
--------------------------- */
p:not(:last-child) {
  margin: 0 0 $spc-S 0;
}
img{
  max-width: 100%;
  display: block;
}
a:link,
a:visited,
a:hover,
a:active {
}

.attention_txt{
  font-size: 12px;
}

.pc{
  display: block;
  @include screen-mq(sm) {
    display: none;
  }
}

.sp{
  display: none;
  @include screen-mq(sm) {
    display: block;
  }
}