@charset "UTF-8";

/* ---------------------------
  common
--------------------------- */
::selection
{
    background: $color-base-4;
    color: #ffffff;
}

.fix_btn{
  position: fixed;
  bottom:40px;
  right: 40px;
    @include screen-mq(sm) {
      bottom:0;
      right: 0;
    }
}

/* ---------------------------
  01_index
--------------------------- */
.o-page-home {
  .o-mainvisual {
    background: $color-base-4;
    margin-top: 73px;
    padding-top: 40px;
    @include screen-mq(sm) {
      margin-top: 56px;
      padding-top: 20px;
    }
    .__wrap{
      .__inner{
        .__box{
          .__block{
            
          }
        }
      }
    }
  }
  /*  コンセプトここから  */
  #concept{
    background: $color-gray;
    .__wrap{
      padding: 80px 0 0;
      position: relative;
      margin-bottom: -60px;
        @include screen-mq(sm) {
          margin-bottom: -20px;
        }
      @include screen-mq(sm) {
        padding: 40px 0 0;
      }
      .__inner{
        background: $color-white;
        border-radius: 16px;
        padding: 80px;
        @include screen-mq(sm) {
          padding: 32px;
          border-radius: 8px;
        }
        h2{
          color: $color-base-4;
        }
        .__box{
          .__block{
            
          }
        }
      }
      .concept_illust{
        position: relative;
        top: -60px;
        @include screen-mq(sm) {
          top: -20px;
        }
      }
    }
  }
  #what{
    background: $color-gray2;
    .__wrap{
      padding: 120px 0;
        @include screen-mq(sm) {
          padding: 60px 0;
        }
      .__inner{
        background: $color-white;
        border-radius: 16px;
        padding: 80px;
        @include screen-mq(sm) {
          border-radius: 8px;
          padding: 32px;
        }
        h2{
          color: $color-base-4;
        }
        .__box.__img_box{
          img{
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
  #outline{
    .__wrap{
      padding: 120px 0;
      @include screen-mq(sm) {
        padding: 60px 0;
      }
      .__inner{
        h2{
        }
        .__box{
          max-width: 1040px $spc-L;
          margin: 0 auto $spc-L;
          .o-tabel01{
            margin-bottom: $spc-L;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  #speaker{
    background: $color-base-4;
    .__wrap{
      padding: 120px 0;
      @include screen-mq(sm) {
        padding: 60px 0;
      }
        h2{
          color: $color-white;
          span{
            color: $color-white;
          }
        }
      .__inner{
        p.first_txt{
          color: $color-white;
          margin-bottom: $spc-L;
            @include screen-mq(sm) {
              margin-bottom: $spc-R;
            }
        }
        .__box.speaker_list{
          .__block.speaker_block{
            display: flex;
            background: $color-white;
            border-radius: 16px;
            padding: 40px;
            margin-bottom: 40px;
            @include screen-mq(sm) {
              border-radius: 8px;
              padding: 32px;
            }
            &:last-child{
              margin-bottom: 0;
            }
            @include screen-mq(sm) {
              display: block;
            }
              .__photo_block{
                width: 32%;
                @include screen-mq(sm) {
                  width: 100%;
                  margin-bottom: 20px;
                }
              }
              .__prof_block{
                width: 66%;
                @include screen-mq(sm) {
                  width: 100%;
                }
                .speaker_position{
                  margin-bottom: $spc-S;
                  li{
                    font-size: 1.4rem;
                    color: #999;
                  }
                }
                .speaker_prof{
                  margin-bottom: 1.6rem;
                  li{
                    padding-left: 1em;
                    text-indent: -1em;
                  }
                }
                .o-tabel02{
                  tr{
                    th{
                      width: 210px;
                    }
                  }
                }
              }
          }
        }
      }
    }
  }
  #timetable{
    .__wrap{
      padding: 120px 0;
      @include screen-mq(sm) {
        padding: 60px 0;
      }
        h2{
        }
      .__inner{
        .__box{
          max-width: 1040px;
          margin: 0 auto;
        }
      }
    }
  }
  #access{
    background: $color-base-4;
    .__wrap{
      padding: 120px 0;
      @include screen-mq(sm) {
        padding: 60px 0;
      }
        h2{
          color: $color-white;
          span{
            color: $color-white;
          }
        }
      .__inner{
        background: $color-white;
        border-radius: 16px;
        padding: 80px;
          @include screen-mq(sm) {
            padding: 32px;
            border-radius: 8px;
          }
        .__box{
          margin-bottom: $spc-R;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  #cta{
      background: url("../img/site/100_index/cta_bg.jpg") center / cover no-repeat;
    .__wrap{
      padding: 120px 0;
      @include screen-mq(sm) {
        padding: 60px 0;
      }
        h2{
          color: $color-base-4;
        }
      .__inner{
        background: $color-white;
        padding: 80px;
        border-radius: 32px;
        text-align: center;
        @include screen-mq(sm) {
          padding: 40px;
        border-radius: 16px;
        }
      }
    }
  }
  #attention{
    background: $color-gray2;
    .__wrap{
      padding: 120px 0;
      @include screen-mq(sm) {
        padding: 60px 0;
      }
        h2{
        }
      .__inner{
        h2{
          font-size: 24px;
        }
        .__box{
          .__block{
            margin-bottom: $spc-R;
            h3{
              font-size: 18px;
              border-bottom: 1px solid #ccc;
              margin-bottom: $spc-S;
            }
            &.cancel_poricy{
              @include screen-mq(sm) {
              .o-tabel01{
                tr{
                  th,td{
                    display: table-cell;
                  }
                  th{
                    width:25%;
                  }
                  td{
                    
                  }
                }
              }
              }
            }
          }
        }
      }
    }
  }
}