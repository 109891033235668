@charset "UTF-8";

.btn01{
  color: $color-white;
  background: $color-yellow;
  padding: 16px;
  border-radius: 40px;
  display: block;
  max-width: 260px;
  text-align: center;
  text-decoration: none;
  margin:  0 auto;
  cursor: pointer;
  $outerBorderWidth: 2px;
  $innerBorderWidth: 4px;

  position: relative;

  @include hideTapHighlightColor();
  @include hardwareAccel();
  @include improveAntiAlias();

  &:before {
      content: '';
      position: absolute;
      border: $color-yellow solid $outerBorderWidth;
      border-radius: 40px;
      top: -($outerBorderWidth + $innerBorderWidth) * 2;
      right: -($outerBorderWidth + $innerBorderWidth) * 2;
      bottom: -($outerBorderWidth + $innerBorderWidth) * 2;
      left: -($outerBorderWidth + $innerBorderWidth) * 2;
      opacity: 0;
      transition-duration: .3s;
      transition-property: top right bottom left;
  }

  &:hover:before {
      top: -($outerBorderWidth + $innerBorderWidth);
      right: -($outerBorderWidth + $innerBorderWidth);
      bottom: -($outerBorderWidth + $innerBorderWidth);
      left: -($outerBorderWidth + $innerBorderWidth);
      opacity: 1;
  }
  &:after{
    content: "";
    padding-left: 13px;
    background: url(../img/site/000_common/outlink.png) no-repeat center left;
    background-size: 100%;
    width: 11px;
    height: 10px;
    margin-left: 4px;
  }
}

.btn02{
  border:1px solid $color-white;
  color: $color-white;
  padding: 16px;
  border-radius: 40px;
  display: block;
  max-width: 260px;
  text-align: center;
  text-decoration: none;
  margin:  0 auto;
  cursor: pointer;
  $outerBorderWidth: 4px;
  $innerBorderWidth: 4px;

  position: relative;

  @include hideTapHighlightColor();
  @include hardwareAccel();
  @include improveAntiAlias();

  &:before {
      content: '';
      position: absolute;
      border: $color-white solid $outerBorderWidth;
      border-radius: 40px;
      top: -($outerBorderWidth + $innerBorderWidth) * 2;
      right: -($outerBorderWidth + $innerBorderWidth) * 2;
      bottom: -($outerBorderWidth + $innerBorderWidth) * 2;
      left: -($outerBorderWidth + $innerBorderWidth) * 2;
      opacity: 0;
      transition-duration: .3s;
      transition-property: top right bottom left;
  }

  &:hover:before {
      top: -($outerBorderWidth + $innerBorderWidth);
      right: -($outerBorderWidth + $innerBorderWidth);
      bottom: -($outerBorderWidth + $innerBorderWidth);
      left: -($outerBorderWidth + $innerBorderWidth);
      opacity: 1;
  }
}