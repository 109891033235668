@charset "UTF-8";

/* ---------------------------
	media query
--------------------------- */
@mixin screen-mq($bp-key) {
  @media #{map-get($bp-list, $bp-key)} {
    @content;
  }
}

/* ---------------------------
	hack
--------------------------- */
@mixin ie11-hack {
  @media all and (-ms-high-contrast:none) {
    *::-ms-backdrop, body {
      @content;
    }
  }
}
@mixin edge-hack {
  @supports (-ms-ime-align:auto) {
    @content;
  }
}
@mixin firefox-hack {
  @-moz-document url-prefix() {
    @content;
  }
}
@mixin chrome-hack {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @content;
  }
}
@mixin safari-hack {
  _::-webkit-full-page-media, _:future, :root .safari-hack {
    @content;
  }
}

/* ---------------------------
	animation
--------------------------- */
@mixin hover-effect {
  animation: animation-hopping 1.5s ease-out 0s infinite forwards;
}

@mixin hideTapHighlightColor() {
    //Prevent highlight colour when element is tapped
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@mixin hardwareAccel() {
    //Improve performance on mobile/tablet devices
    transform: translateZ(0);
}

@mixin improveAntiAlias() {
    //Improve aliasing on mobile/tablet devices
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
