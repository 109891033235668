@charset "UTF-8";

.o-tabel01 {
  width: 100%;
    tbody {
        tr {
            th,td {
              border: 1px solid #fff;
            }
            th {
              text-align: left;
              font-weight: normal;
              width: 180px;
              padding: $spc-XS;
              vertical-align: middle;
              background: $color-gray3;
              font-weight: bold;
            }
            td {
              padding: $spc-XS;
              width: auto;
              background: $color-gray2;
            }
        }
        @include screen-mq(md) {
          tr {
              th,td {
                display: block;
                width: 100%;
              }
              
          }
        }
		@include screen-mq(xs) {
    }
}
}

.o-tabel02 {
  width: 100%;
    tbody {
        tr {
          border-top: 1px solid #ccc;
            &:last-child{
              border-bottom: 1px solid #ccc;
            }
            th,td {
            }
            th {
              text-align: left;
              font-weight: normal;
              width: 180px;
              padding: $spc-XS;
              vertical-align: middle;
              font-weight: bold;
              @include screen-mq(xs) {
                padding: $spc-S $spc-XS 0;
              }
            }
            td {
              padding: $spc-XS;
              width: auto;
              @include screen-mq(xs) {
                padding: $spc-XXXS $spc-XS $spc-S;
              }
            }
        }
        @include screen-mq(md) {
          tr {
              th,td {
                display: block;
                width: 100%;
              }
              
          }
        }
		@include screen-mq(xs) {
    }
}
}

.o-tabel03 {
  width: 100%;
    tbody {
        tr {
          &:nth-child(odd){
              background: $color-gray3;
          }
            th,td {
            }
            th {
              text-align: left;
              font-weight: normal;
              width: 180px;
              padding: $spc-XS;
              vertical-align: middle;
              font-weight: bold;
            }
            td {
              padding: $spc-XS;
              width: auto;
            }
        }
        @include screen-mq(md) {
          tr {
            th,td {
            }
            th{
              width: 20%;
            }
            td{
              width: 80%;
            } 
          }
        }
		@include screen-mq(xs) {
    }
}
}