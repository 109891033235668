@charset "UTF-8";


h2.__head{
  font-family: $font-Lexend;
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: $spc-R;
  line-height: 1.0em;
  span{
    font-size: 1.2rem;
    color: $color-base-4;
  }
  @include screen-mq(sm) {
    font-size: 2.4rem;
  }
}